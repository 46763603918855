
#report {
    /*margin-top: 37px;*/
    background: linear-gradient(114.8deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.01) 100%);
    border-radius: 23px;
    border-color: #e64900;
}

.card-border {
    background: linear-gradient(175deg, rgba(230, 73, 0, 0.2) 0%, rgba(230, 73, 0, 0) 100%);
    box-shadow: rgba(0, 0, 0, 0.18) 20px 20px 15px, rgba(255, 255, 255, 0.01) -10px 10px 15px inset, rgba(255, 255, 255, 0.01) 10px -10px 15px inset;
    border-radius: 23px;
}

.card-container {
    width: 100%;
    height: 100%;
    background: rgba(230, 73, 0, 0.04);
    border-radius: 23px;
}

.report-bg {
    /*background-color: #19191a;*/
    background-color: #02040e;
    position: relative;
    height: 800px;
    z-index: 67;
}

.report-transition-text {
    font-size: 20px;
    font-family: "Lato", sans-serif;
    color: #edeeef;
}

.report-title-margin {
    margin-top: 100px;
}

@media (min-width: 500px) {
    .report-title-margin {
        margin-top: 100px;
    }
}

@media (min-width: 960px) {
    .report-title-margin {
        margin-top: 115px;
    }
}

.report-header {
    border-bottom: 1px solid rgba(237, 238, 239, 0.125);
}

.report-title {
    font-size: 26px;
    font-family: "Lato", sans-serif;
    color: rgb(255, 255, 255);
    line-height: 1.2;
}

.report-title-email {
    font-size: 18px;
    font-family: "Lato", sans-serif;
    color: #edeeef;
    margin-top: 6px;
}

.report-number {
    font-size: 36px;
    font-weight: bold;
    font-family: "Lato", sans-serif;
    color: rgb(255, 255, 255);
    line-height: 1.2;
}

.report-label {
    font-size: 22px;
    font-family: "Lato", sans-serif;
    color: rgb(255, 255, 255);
    line-height: 1.2;
}

.report-description-text {
    font-size: 16px;
    font-family: "Lato", sans-serif;
    color: #edeeef;
}

.report-divider {
    border-top: #e64900 2px solid;
    border-bottom: none;
}

