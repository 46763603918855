.breach-logo {
    width: 115px;
    max-height: 100px;
}

@media (max-width: 500px) {
    .breach-logo {
        margin-left: -10px;
    }
}

.breach-label {
    font-size: 16px;
    font-family: "Lato", sans-serif;
    font-weight: bold;
    color: #edeeef;
}

.breach-description {
    font-size: 16px;
    font-family: "Lato", sans-serif;
    color: #edeeef;
}

.breach-description > a {
    font-size: 16px;
    font-family: "Lato", sans-serif;
    color: #edeeef;
}

.breach-description > a:hover {
    font-size: 16px;
    font-family: "Lato", sans-serif;
    color: #e64900;
}
