.atf-bg {
    background-color: #02040e;
    position: relative;
    height: 800px;
    z-index: 67;
}

.atf-title {
    font-size: 53px;
    font-family: "Lato", sans-serif;
    color: rgb(255, 255, 255);
    font-weight: bold;
    line-height: 1.2;
}

.atf-title-margin {
    margin-top: 100px;
}

@media (min-width: 500px) {
    .atf-title-margin {
        margin-top: 80px;
    }
}

@media (min-width: 960px) {
    .atf-title-margin {
        margin-top: 115px;
    }
}

.atf-secondary {
    font-size: 22px;
    font-family: "Lato", sans-serif;
    color: rgb(255, 255, 255);
    line-height: 1.2;
}

.atf-call-to-action {
    font-family: "Lato", sans-serif;
    /* https://colorcodes.io/orange/construction-orange-color-codes/ */
    background-color: #e64900;
    border: none;
    font-weight: 625;
    outline: none;
    /**width: 277px;**/
    height: 45px;
    font-size: 22px;
    color: rgb(255, 255, 255);
    line-height: 0.891;
    text-align: center;
    padding-bottom: 3px;
}

.atf-call-to-action:hover {
    background-color: #ff3a00;
    border: none;
    outline: none;
}

.atf-call-to-action:focus {
    background-color: #e64900;
    box-shadow: none !important;
    border: none;
    outline: none !important;
}

.atf-call-to-action:active  {
    box-shadow: none !important;
    border: none;
    outline: none !important;
}

.filter-white {
    filter: invert(100%) sepia(7%) saturate(7470%) hue-rotate(213deg) brightness(113%) contrast(94%);
}
