html {
  background-color: #02040e;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

nav {
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
  transition: all .1s linear;
}

.navbar {
  background-color: #02040e;
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.navbar-brand {
  color: #e64900 !important;
  font-family: "Lato", sans-serif;
  font-size: 30px;
  font-weight: bold;
  padding-left: 12px;
  padding-top: 1rem;
  padding-bottom: 0.25rem;
}

/* Highlights input borders. */
.form-control:focus {
  box-shadow: 0 0 6px #e64900;
  border-color: #e64900;
}

